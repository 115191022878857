import {Box, Chip, Container, Stack, Typography} from "@mui/material";
import Footer from "../components/Footer";
import useScrollToTop from "../hooks/useScrollToTop";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import HeroParallax from "../components/HeroParallax";
import {padNumberWithZeros} from "../utils/utils";
import Loading from "../components/Loading";
import {useContext, useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import mainImage from "../assets/references.jpeg";
import ConfigContext from "../context/ConfigContext";
import ParallaxImage from "../components/ParallaxImage";
import {Helmet} from "react-helmet";


const References = () => {
    document.title = 'Trilogic'
    useScrollToTop()
    const [loading, setLoading] = useState(false)
    const [references, setReferences] = useState([])
    const {getData: fetchReferences} = useCallDataApi('references')
    const {t} = useTranslation()
    const {language} = useContext(ConfigContext)

    useEffect(() => {
        setLoading(true)
        fetchReferences().then(r => {
            setReferences(r)
        }).finally(() => setLoading(false))
    }, [])

    return <DrawerAppbar fade>

        <Helmet>
            <meta name="description" content="This is the home page of my website." />
            <meta property="og:title" content="Home Page - My Website" />
            <meta property="og:description" content="This is the home page of my website." />
            <meta property="og:url" content="https://www.mywebsite.com/" />
            <meta property="og:type" content="website" />
        </Helmet>

        <HeroParallax description={t('references.description')} title={t('references.title')} underlineColor='#E6FF01' image={mainImage}/>
        {references?.map((reference, index) => (
            <Box key={index}>
                {index % 2 === 0 ? (
                    <Container sx={{ marginTop: 3 }}>
                        <Typography variant='h1' gutterBottom>
                            {padNumberWithZeros(index + 1, 2)} {reference?.translations?.[language]?.name}
                        </Typography>
                        <Stack spacing={1} direction='row' marginBottom={2}>
                            {reference?.tags?.map(tag => (
                                <Chip key={tag?.id} label={'#' + tag?.translations?.[language]?.name} />
                            ))}
                        </Stack>
                        <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }} gutterBottom textAlign='justify'>
                            {reference?.translations?.[language]?.description}
                        </Typography>
                    </Container>
                ) : (
                    <Box sx={{ width: '100%', background: '#000', padding: 4 }}>
                        <Container>
                            <Typography color='#fff' variant='h1' gutterBottom>
                                {padNumberWithZeros(index + 1, 2)} {reference?.translations?.[language]?.name}
                            </Typography>
                            <Stack spacing={1} direction='row' marginBottom={2}>
                                {reference?.tags?.map(tag => (
                                    <Chip
                                        key={tag?.id}
                                        sx={{ background: '#fff', color: '#000' }}
                                        label={'#' + tag?.translations?.[language]?.name}
                                    />
                                ))}
                            </Stack>
                            <Typography variant='body1' sx={{ whiteSpace: 'pre-line' }} gutterBottom color='#fff' textAlign='justify'>
                                {reference?.translations?.[language]?.description}
                            </Typography>
                        </Container>
                    </Box>
                )}
                <ParallaxImage src={reference?.img} alt={reference?.translations?.[language]?.name} />
            </Box>
        ))}
        <Loading isLoading={loading}/>
        <Footer/>
    </DrawerAppbar>
}

export default References