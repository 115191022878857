import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import logo from '../assets/trilogic_logo_monochrome_light.png';
// import mandatory from "../utils/mandatory";
// import TikTokIcon from "../TikTokIcon";
// import {facebook, instagram, tiktok} from "../utils/socials";
import {useTranslation} from "react-i18next";
import mandatory from "../utils/mandatory";

const Footer = () => {
    const {t} = useTranslation()
    return (
        <Box sx={{ bgcolor: 'primary.900', color: 'white', py: 3 }}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            {t('footer.aboutUs')}
                        </Typography>
                        <Box mb={2}>
                            <img src={logo} alt="Logo" style={{ maxWidth: '50%', height: 'auto' }} /> {/* Add the logo image */}
                        </Box>
                        <Typography variant="body2">
                            {t('footer.aboutUsDescription')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            {t('footer.links')}
                        </Typography>
                        {mandatory?.map(item => (
                            <Link key={item?.name} href={item?.url} color="inherit" variant="body2" display="block" gutterBottom>
                                {t(item?.name)}
                            </Link>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom>
                            {t('footer.contact')}
                        </Typography>
                        <Typography variant="body2" display="block">
                            H-3300 Eger, Kossúth Lajos utca 6
                        </Typography>
                        <Typography variant="body2" display="block">
                            Tel.: +36 30 380 53 40
                        </Typography>
                        <Typography variant="body2" display="block" gutterBottom>
                            Email: cloud@trilogic.hu
                        </Typography>
                        <Box>
                            {/*<IconButton*/}
                            {/*    color="inherit"*/}
                            {/*    component="a"*/}
                            {/*    href={facebook}*/}
                            {/*    target="_blank"*/}
                            {/*    rel="noopener noreferrer"*/}
                            {/*>*/}
                            {/*    <FacebookIcon />*/}
                            {/*</IconButton>*/}
                            {/*<IconButton*/}
                            {/*    color="inherit"*/}
                            {/*    component="a"*/}
                            {/*    href={tiktok}*/}
                            {/*    target="_blank"*/}
                            {/*    rel="noopener noreferrer"*/}
                            {/*>*/}
                            {/*    <TikTokIcon width='22px' height='22px'/>*/}
                            {/*</IconButton>*/}
                            {/*<IconButton*/}
                            {/*    color="inherit"*/}
                            {/*    component="a"*/}
                            {/*    href={instagram}*/}
                            {/*    target="_blank"*/}
                            {/*    rel="noopener noreferrer"*/}
                            {/*>*/}
                            {/*    <InstagramIcon />*/}
                            {/*</IconButton>*/}

                        </Box>
                    </Grid>
                </Grid>
                <Box textAlign="center" mt={5}>
                    <Typography variant="body2">
                        &copy; {new Date().getFullYear()} Trilogic Cloud Kft. {t('footer.allRightsReserved')}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;