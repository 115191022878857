const mandatory = [
    {
        name: 'mandatory.impressum',
        url: '/impressum',
    }, {
        name: 'mandatory.privacyPolicy',
        url: '/privacy-policy',
    },
]

export default mandatory