import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { useInView } from 'react-intersection-observer';
import Aurora from './Aurora'; // Import your Aurora component
import logoImage from '../assets/trilogic_logo_monochrome_light.png';

import { createImageUrl } from '../utils/utils';
import ScrollDownIcon from "./ScrollDownIcon"; // Adjust the path as needed

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const HeroContainer = styled(Box)(({ image, darken, parallaxOffset }) => ({
    position: 'relative',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: `center ${parallaxOffset}px`,
    backgroundImage: `linear-gradient(rgba(0, 0, 0, ${darken || 0.3}), rgba(0, 0, 0, ${darken || 0.3})), url(${typeof image === 'string' ? image : createImageUrl(image)})`,
}));

const Logo = styled('img')(({ inView }) => ({
    animation: inView ? `${fadeIn} 2s ease-in-out` : 'none',
    opacity: inView ? 1 : 0,
    position: 'relative',
    width: 150,
    height: 'auto',
    zIndex: 2,
}));

const Title = styled(Typography)(({ inView, underlineColor }) => ({
    color: '#fff',
    fontSize: '3rem',
    fontWeight: 'bold',
    animation: inView ? `${fadeIn} 2s ease-in-out` : 'none',
    opacity: inView ? 1 : 0,
    position: 'relative',
    zIndex: 2,
    '::after': underlineColor
        ? {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -6,
            width: '100%',
            height: 5,
            backgroundColor: underlineColor,
        }
        : {},
}));

const Description = styled(Typography)(({ inView }) => ({
    color: '#fff',
    fontSize: '1.25rem',
    textAlign: 'center',
    maxWidth: '800px',
    lineHeight: '20px',
    animation: inView ? `${slideIn} 2s ease-in-out` : 'none',
    opacity: inView ? 1 : 0,
    position: 'relative',
    zIndex: 2,
    marginTop: '1rem', // Adjust margin as needed
}));

const HeroImage = ({ title, description, image, darken, underlineColor }) => {

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const [parallaxOffset, setParallaxOffset] = useState(0);

    const handleScroll = () => {
        const offset = window.scrollY * 0.5; // Adjust the multiplier to change the parallax effect strength
        setParallaxOffset(offset);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <HeroContainer ref={ref} image={image} darken={darken} parallaxOffset={parallaxOffset}>
            <Aurora>
                <div style={{position: 'absolute', right: '50%', bottom: '20px', zIndex: '10'}}>
                    <ScrollDownIcon size={20} color='white' />
                </div>
            </Aurora> {/* Add Aurora Component */}
            {/*<Logo inView={inView} src={logoImage} alt="Logo" />*/}
            {/*<Title inView={inView} variant="h1" underlineColor={underlineColor}>*/}
            {/*    {title}*/}
            {/*</Title>*/}
            {/*<Description inView={inView}>*/}
            {/*    {description}*/}
            {/*</Description>*/}
        </HeroContainer>
    );
};

export default HeroImage;
