import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {ConfigProvider} from "./context/ConfigContext";
import trilogicTheme from "./components/themes/TrilogicTheme";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import References from "./pages/Refernces";
import Contact from "./pages/Contact";
import Impressum from "./pages/Impressum";
import PrivacyPolicy from "./pages/PrivacyPolicy";


const App = () => {

  return <ThemeProvider theme={trilogicTheme}>
    <ConfigProvider>
      <Router>
        <Switch>
          <Route path="/" component={Home} exact/>
          <Route path="/references" component={References} exact/>
          <Route path="/contact" component={Contact} exact/>

          <Route path="/impressum" component={Impressum} exact/>
          <Route path="/privacy-policy" component={PrivacyPolicy} exact/>

          <Route component={NotFound}/>
        </Switch>
      </Router>
    </ConfigProvider>
  </ThemeProvider>
}

export default App;