import {Box, Container, Grid, Typography} from "@mui/material";
import Footer from "../components/Footer";
import useScrollToTop from "../hooks/useScrollToTop";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import HeroImage from "../components/HeroImage";
import ImageComponent from "../components/ImageWrapper";
import docker from "../assets/logos/Docker_logo.png"
import python from "../assets/logos/python.png"
import js from "../assets/logos/js.png"

const NotFound = () => {
    document.title = 'Trilogic'
    useScrollToTop()
    const {t} = useTranslation()

    const logos = [{
        name: 'docker',
        src: docker
    }, {
        name: 'python',
        src: python
    }, {
        name: 'js',
        src: js
    }]


    return <DrawerAppbar fade>
        <HeroImage description={t('corporate.description')} title={t('corporate.title')} underlineColor='#ff0000'/>
        <Container sx={{marginTop: 3}}>
            <Typography variant='h1' gutterBottom >{t('home.heading')}</Typography>
            <Typography variant='body1' sx={{whiteSpace: 'pre-line'}} gutterBottom>{t('home.description')}</Typography>
        </Container>
        <Box sx={{width: '100%', background: '#000', marginTop: 4, padding: 4}}>
            <Container>
                <Typography color='#fff' variant='h1' gutterBottom >{t('home.heading2')}</Typography>
                <Typography variant='body1' sx={{whiteSpace: 'pre-line'}} gutterBottom color='#fff'>{t('home.description2')}</Typography>
            </Container>
        </Box>
        <Container sx={{marginTop: 3, marginBottom: 3}}>
            <Typography variant='h1' gutterBottom >{t('home.heading3')}</Typography>
            <Typography variant='body1' sx={{whiteSpace: 'pre-line'}} gutterBottom>{t('home.description3')}</Typography>
            <Grid container>
                {logos?.map(logo => <Grid item key={logo?.name} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><ImageComponent src={logo?.src} alt={logo?.name} /></Grid>)}
            </Grid>
        </Container>
        <Footer/>
    </DrawerAppbar>
}

export default NotFound