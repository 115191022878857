import {Box, Button, Checkbox, Container, FormControlLabel, Grid, IconButton, Stack, Typography} from "@mui/material";
import Footer from "../components/Footer";
import useScrollToTop from "../hooks/useScrollToTop";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HeroParallax from "../components/HeroParallax";
import mainImage from "../assets/contact.jpg"
import useCallDataApi from "../hooks/data";
import {useRef, useState} from "react";
import {validateRequired} from "../components/formValidation/validators";
import CustomTextField from "../components/formValidation/CustomTextField";
import ErrorHandlingForm from "../components/formValidation/ErrorHandlingForm";
import {useSnackbar} from "notistack";
import Loading from "../components/Loading";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const Contact = () => {
    document.title = 'Trilogic'
    useScrollToTop()
    const {t} = useTranslation()

    const {postData: postContact} = useCallDataApi('contact')
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const initialState = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        comment: ''
    }
    const [formData, setFormData] = useState(initialState);

    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        comment: false,
    });

    const validators = {
        first_name: [validateRequired],
        last_name: [validateRequired],
        phone: [],
        email: [validateRequired],
        comment: [validateRequired],
    };

    const contactRef = useRef(null);


    return <DrawerAppbar fade>

        <Helmet>
            <meta name="description" content="This is the home page of my website." />
            <meta property="og:title" content="Home Page - My Website" />
            <meta property="og:description" content="This is the home page of my website." />
            <meta property="og:url" content="https://www.mywebsite.com/" />
            <meta property="og:type" content="website" />
        </Helmet>

        <HeroParallax description={t('contact.description')} title={t('contact.title')} underlineColor='#E6FF01' image={mainImage}/>
        <Box sx={{ marginTop: '64px', marginBottom: '64px' }}>
            <Container>
                <Typography variant="h1" component="h1" gutterBottom textAlign='center'>
                    {t('contact.contact')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sx={{ mx: 'auto' }}>
                        <ErrorHandlingForm
                            data={formData}
                            validators={validators}
                            setErrors={setErrors}
                            errors={errors}
                            setData={setFormData}
                            reference={contactRef}
                        >
                            <Stack spacing={2}>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('contact.lastName')}
                                    </Typography>
                                    <CustomTextField
                                        name='last_name'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('contact.firstName')}
                                    </Typography>
                                    <CustomTextField
                                        name='first_name'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('contact.phone')}
                                    </Typography>
                                    <CustomTextField
                                        name='phone'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('contact.email')}
                                    </Typography>
                                    <CustomTextField
                                        name='email'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('contact.comment')}
                                    </Typography>
                                    <CustomTextField
                                        name='comment'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                        multiline
                                        rows={3}
                                    />
                                </Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={(event) => setIsChecked(event.target.checked)}
                                            name="consent"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            {t('general.consent')}{" "}
                                            <Link to="/privacy-policy" target="_blank" rel="noopener">
                                                {t('general.privacyPolicy')}
                                            </Link>
                                        </Typography>
                                    }
                                />
                                <Button disabled={!isChecked} sx={{background: '#E6FF01', color: '#000', '&:hover': {background: '#a4b600'}}} variant='contained' onClick={() => {
                                    const data = contactRef.current.getData();
                                    if (data) {
                                        setLoading(true)
                                        postContact('', data)
                                            .then(() => {
                                                enqueueSnackbar(t('general.success'), {variant: 'success'})
                                                setFormData(initialState)
                                            })
                                            .finally(() => setLoading(false))
                                    }

                                }}>{t('general.send')}</Button>
                            </Stack>
                        </ErrorHandlingForm>

                    </Grid>
                </Grid>
                <Box mt={4}>
                    <Typography variant="body1" gutterBottom>
                        {t('contact.contactText')}
                    </Typography>
                </Box>
                <Box mt={4} display="flex" justifyContent="space-around">
                    <Box textAlign="center">
                        <IconButton color="primary">
                            <LocationOnIcon fontSize="large" />
                        </IconButton>
                        <Typography variant="body1" gutterBottom>
                            H-3300 Eger, Kossúth Lajos utca 6
                        </Typography>
                    </Box>
                    <Box textAlign="center">
                        <IconButton color="primary">
                            <EmailIcon fontSize="large" />
                        </IconButton>
                        <Typography variant="body1" gutterBottom>
                            cloud@trilogic.hu
                        </Typography>
                    </Box>
                    <Box textAlign="center">
                        <IconButton color="primary">
                            <PhoneIcon fontSize="large" />
                        </IconButton>
                        <Typography variant="body1" gutterBottom>
                            +36 30 380 53 40
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>

        <Loading isLoading={loading}/>
        <Footer/>
    </DrawerAppbar>
}

export default Contact