import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import {createImageUrl} from "../utils/utils";


const HeroContainer = styled(Box)(({ image, parallaxOffset }) => ({
    position: 'relative',
    height: '50vh',
    backgroundSize: 'cover',
    backgroundPosition: `center ${parallaxOffset}px`,
    backgroundImage: `url(${typeof image === 'string' ? image : createImageUrl(image)})`,
}));


const ParallaxImage = ({ src }) => {

    const [parallaxOffset, setParallaxOffset] = useState(0);

    const handleScroll = () => {
        const offset = window.scrollY * 0.8; // Adjust the multiplier to change the parallax effect strength
        setParallaxOffset(offset);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <HeroContainer image={src} parallaxOffset={parallaxOffset} />
};

export default ParallaxImage;