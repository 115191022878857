import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    menu: {
                        aboutUs: '01 About Us',
                        references: '02 References',
                        contact: '03 Contact',
                    },
                    home: {
                        heading: '01 Trilogic software',
                        description: 'Welcome to Trilogic Software! We\'re a team of passionate developers, designers, and tech enthusiasts who believe in the power of simplicity. Our mission? To make technology work for you without the headaches. Whether you’re a startup looking to build your first app or a large business in need of streamlined software solutions, we’ve got you covered.\n' +
                            '\n' +
                            'At Trilogic, we take the complicated out of software. Our approach is all about understanding your needs and delivering solutions that make your life easier. We’re not just about building software; we’re about building relationships and helping you achieve your goals.\n' +
                            '\n' +
                            'We’re here to make sure your tech works for you, not the other way around. So, whether you need a custom-built solution, want to optimize your existing software, or just need some tech advice, Trilogic is here to help. Let’s make something great together.',
                        heading2: '02 Trilogic mindset',
                        description2: "At Trilogic Software, we live and breathe Agile development. But what does that really mean for you?\n" +
                            "\n" +
                            "Flexibility at Its Finest We understand that ideas evolve, markets change, and new opportunities pop up unexpectedly. Our Agile approach lets us adapt quickly to your shifting needs without missing a beat. Need to change a feature mid-project? No problem. We're all about rolling with the punches to deliver the best possible product.\n" +
                            "\n" +
                            "Collaborate and Listen You're not just a client; you're part of the team. We believe in constant communication and collaboration throughout the development process. Regular check-ins, updates, and feedback sessions ensure that we're always on the same page and heading in the right direction together.\n" +
                            "\n" +
                            "Breaking it Down Big projects can be overwhelming. That's why we break everything down into manageable chunks called sprints. Each sprint tackles specific goals and delivers tangible results, so you can see progress every step of the way. This iterative process helps us catch and fix issues early, saving time and resources in the long run.\n" +
                            "\n" +
                            "Quality Over Quantity By focusing on small, incremental improvements, we maintain a high standard of quality throughout the project. Continuous testing and refinement mean that the final product isn't just done—it's done right.\n" +
                            "\n" +
                            "Delivering Value Quickly We aim to deliver functional software early and often. This means you start reaping the benefits sooner, and we can gather real-world feedback to make the product even better. It's all about creating value from day one.\n" +
                            "\n" +
                            "Continuous Improvement We're always looking for ways to improve our processes and deliverables. After each sprint, we take the time to reflect on what went well and what could be better. This commitment to learning and growth helps us stay sharp and deliver top-notch solutions every time.\n" +
                            "\n" +
                            "Why It Matters to You Our Agile mindset ensures that you're involved, informed, and satisfied throughout the development journey. It makes the process transparent, efficient, and tailored to your unique needs. With Trilogic, you get a partner who's dedicated to turning your vision into reality—smoothly and effectively.\n" +
                            "\n" +
                            "Ready to experience the Trilogic difference? Let's work together and create something amazing!",
                        heading3: '03 Technologies',
                        description3: "In the world of modern software technologies, we’ve gone from \"Hello, World!\" to \"Oops, did you mean to push that to production?\" faster than you can say \"AI-driven, cloud-native, blockchain-enabled microservices.\" We’ve got apps that can order your coffee, remind you to drink it, and even tweet about how productive you’re being—all while you're still looking for the start button. In short, today’s tech is so advanced, it’s practically doing the work for us... except when it’s updating. Then, we’re all just staring at the loading screen, wondering what we did to deserve this. Here are a few examples:"
                    },
                    contact: {
                        title: 'Contact us',
                        description: '',
                        contact: 'Write us something!',
                        firstName: 'First name',
                        lastName: 'Last name',
                        email: 'Email',
                        phone: 'Phone',
                        comment: 'Comment',
                        contactText: 'Something here about contacting us...'
                    },
                    references: {
                        title: 'References',
                        description: 'Our works from the past few years'
                    },
                    footer: {
                        aboutUs: 'About Trilogic',
                        aboutUsDescription: '',
                        links: 'Quick links',
                        contact: 'If you like what you see...',
                        allRightsReserved: 'All rights reserved.'
                    },
                    general: {
                        send: "Send!",
                        success: "Your message has been successfully sent.",
                        getQuote: "Get a Quote",
                        firstName: "First Name",
                        lastName: "Last Name",
                        phone: "Phone",
                        email: "Email",
                        comment: "Comments",
                        back: "Back",
                        cancel: "Cancel",
                        sum: "Sum",
                        open: "Open",
                    },
                    errors: {
                        charError: 'Can only contain lowercase letters without accents (a-z)',
                        minLengthError: 'Must be at least {{minLength}} characters long',
                        maxLengthError: 'Can be at most {{maxLength}} characters long',
                        emailError: 'Invalid email address',
                        vatNumberError: 'Invalid VAT number, (xxxxxxxx-y-zz)',
                        numberError: 'Can only contain numbers',
                        nonNegativeError: 'Cannot be a negative number',
                        requiredError: 'Cannot be empty',
                        dateError: 'Invalid date',
                    },
                }
            },
            hu: {
                translation: {
                    menu: {
                        aboutUs: '01 Rólunk',
                        references: '02 Referenciáink',
                        contact: '03 Kapcsolat',
                    },
                    contact: {
                        title: 'Vedd fel velünk a lapcsolatot',
                        description: '',
                        contact: 'Írj neünk valamit!',
                        firstName: 'Keresztnév',
                        lastName: 'Vezetéknév',
                        email: 'Email',
                        phone: 'Telefonszám',
                        comment: 'Megjegyzés',
                        contactText: 'Valami...'
                    },
                    general: {
                        send: "Küldés!",
                        success: "Üzenetét sikeresen elküldtük.",
                        getQuote: "Kérjen Árajánlatot",
                        firstName: "Keresztnév",
                        lastName: "Vezetéknév",
                        phone: "Telefon",
                        email: "E-mail",
                        comment: "Megjegyzések",
                        back: "Vissza",
                        cancel: "Mégsem",
                        sum: "Összesen",
                        open: "Megnyitás",
                        consent: "Elfogadom az ",
                        privacyPolicy: "adatkezelési szabályzatban foglaltatkat."
                    },
                    errors: {
                        charError: 'Csak ékezet nélküli kisbetűs karaktereket tartalmazhat (a-z)',
                        minLengthError: 'Legalább {{minLength}} karakter hosszú kell hogy legyen',
                        maxLengthError: 'Legfeljebb {{maxLength}} karakter hosszú lehet',
                        emailError: 'Nem érvényes e-mail cím',
                        vatNumberError: 'Nem érvényes adószám, (xxxxxxxx-y-zz)',
                        numberError: 'Csak számokat tartalmazhat',
                        nonNegativeError: 'Nem lehet negatív szám',
                        requiredError: 'Nem maradhat üresen',
                        dateError: 'Helytelen dátum',
                    },
                }
            }
        }
    });

export default i18n;