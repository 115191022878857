import {Box, Container, Typography} from "@mui/material";
import DrawerAppbar from "../components/DrawerAppbar";
import Footer from "../components/Footer";


const PrivacyPolicy = () => {
    const text = 'Cégnév: Trilogic Cloud Kft.\n\n Ügyvezető igazgató: Tóth Péter\n\n Szakmai tevékenységért felelős: Toldi Gabriella\n\n Székhely: 1113 Budapest, Bocskai út 77-79. B. épület 3. emelet\n\n Adószám: 10274837-2-43\n\n Cégjegyzékszám: 01-09-063771\n\n Telefon: +361 209 2110\n\n Fax: +361 209 2111\n\n E-mail: jettravel@jettravel.hu\n\n Számlavezető pénzintézet: Raiffeisen Bank Zrt.\n\n HUF IBAN: HU08 1200 1008 0147 4956 0010 0001\n\n EUR IBAN: HU08 1200 1008 0147 4956 0020 0008\n\n USD IBAN: HU84 1200 1008 0147 4956 0030 0005\n\n SWIFT: UBRTHUHB\n\n Nyilvántartási szám: R00334/1992/1999\n\n Vagyoni biztosíték biztosítása: AEGON Magyarország Általános Biztosító Zrt.\n\n Vállalati Felelősségbiztosító: AEGON Magyarország Általános Biztosító Zrt.\n\n Tárhely szolgáltató neve: EMAB ZRT\n\n Tárhely szolgáltató székhelye: 1055 Budapest, Falk Miksa u. 4\n\n Tárhely szolgáltató telefonszáma: +361 785 4043\n\n E-mail cím: informatika@emab.hu\n\n'
    return <DrawerAppbar>
        <Box sx={{marginTop: '128px', marginBottom: '64px'}}>
            <Container>
                <Typography variant='h2' gutterBottom>Adatkezelési szabályzat</Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {text}
                </Typography>
            </Container>

        </Box>
        <Footer/>
    </DrawerAppbar>
}

export default PrivacyPolicy