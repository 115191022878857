const menuItems = [
    {
        name: 'menu.aboutUs',
        url: '/',
    }, {
        name: 'menu.references',
        url: '/references'
    }, {
        name: 'menu.contact',
        url: '/contact'
    }
]

export default menuItems