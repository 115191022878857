import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
// 255, 19, 73
const Aurora = ({ blobColor = { r: 1, g: 1, b: 1 }, children }) => {
    const mountRef = useRef(null);

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();

        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);

        // Lava lamp effect
        const geometry = new THREE.PlaneGeometry(5, 5, 32);
        const material = new THREE.ShaderMaterial({
            vertexShader: `
                varying vec2 vUv;
                void main() {
                  vUv = uv;
                  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform float time;
                uniform vec3 blobColor;
                varying vec2 vUv;

                // 2D Random Noise function
                float random(vec2 st) {
                  return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
                }

                // 2D Noise based on distance
                float noise(vec2 st) {
                  vec2 i = floor(st);
                  vec2 f = fract(st);
                  float a = random(i);
                  float b = random(i + vec2(1.0, 0.0));
                  float c = random(i + vec2(0.0, 1.0));
                  float d = random(i + vec2(1.0, 1.0));
                  vec2 u = f * f * (3.0 - 2.0 * f);
                  return mix(a, b, u.x) +
                         (c - a) * u.y * (1.0 - u.x) +
                         (d - b) * u.x * u.y;
                }

                void main() {
                  vec2 pos = vUv * 2.0 - 1.0;

                  // Create layers of noise
                  float n = 0.0;
                  n += 0.5 * noise(pos * 1.0 + vec2(time * 0.1, time * 0.2));
                  n += 0.25 * noise(pos * 2.0 - vec2(time * 0.2, time * 0.1));
                  n += 0.125 * noise(pos * 4.0 + vec2(time * 0.3, time * 0.4));

                  // Create a blob pattern
                  float blobs = smoothstep(0.4, 0.5, n) - smoothstep(0.5, 0.6, n);

                  // Assign colors for the lava lamp effect
                  vec3 color = mix(vec3(0.0, 0.0, 0.0), blobColor, blobs); // Background is black now

                  gl_FragColor = vec4(color, 1.0);
                }
            `,
            uniforms: {
                time: { value: 1.0 },
                blobColor: { value: new THREE.Color(blobColor.r, blobColor.g, blobColor.b) },
            },
        });
        const plane = new THREE.Mesh(geometry, material);
        scene.add(plane);

        camera.position.z = 1;

        const animate = function () {
            requestAnimationFrame(animate);

            material.uniforms.time.value += 0.01; // Adjust speed for a more fluid effect

            renderer.render(scene, camera);
        };

        animate();

        const handleResize = () => {
            renderer.setSize(window.innerWidth, window.innerHeight);
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            mountRef.current?.removeChild(renderer.domElement);
        };
    }, []);

    return <div style={{position: 'relative'}} ref={mountRef}>{children}</div>;
};

export default Aurora;
