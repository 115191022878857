import React from 'react';
import { styled } from '@mui/material/styles';

const ImageContainer = styled('div')(({ imageUrl }) => ({
    // width: '100%', // Adjust as needed
    // height: '100%', // Adjust as needed
    maxWidth: '200px',
    padding: '10px',
    position: 'relative',
    overflow: 'hidden',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        filter: 'brightness(0) saturate(100%)', // Blackens only non-transparent areas
        mixBlendMode: 'multiply', // Applies the blackening effect only on non-transparent parts
        transition: 'filter 0.3s ease-in-out, mix-blend-mode 0.3s ease-in-out',
    },
    '&:hover img': {
        filter: 'none',
        mixBlendMode: 'normal',
    },
}));

const ImageWrapper = ({ src, alt }) => {
    return (
        <ImageContainer imageUrl={src}>
            <img src={src} alt={alt} />
        </ImageContainer>
    );
};

export default ImageWrapper;
