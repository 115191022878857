import {Box, Container, Grid, Typography} from "@mui/material";
import Footer from "../components/Footer";
import useScrollToTop from "../hooks/useScrollToTop";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import HeroImage from "../components/HeroImage";
import ImageComponent from "../components/ImageWrapper";
import docker from "../assets/logos/Docker_logo.png"
import python from "../assets/logos/python.png"
import js from "../assets/logos/js.png"
import {useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import Loading from "../components/Loading";
import {Helmet} from "react-helmet";

const Home = () => {
    document.title = 'Trilogic'
    useScrollToTop()
    const [loading, setLoading] = useState(false)
    const [technologies, setTechnologies] = useState([])
    const {getData: fetchTechnologies} = useCallDataApi('technologies')
    const {t} = useTranslation()

    useEffect(() => {
        setLoading(true)
        fetchTechnologies().then(t => {
            setTechnologies(t)
        }).finally(() => setLoading(false))
    }, [])


    return <DrawerAppbar fade>

        <Helmet>
            <meta name="description" content="This is the home page of my website." />
            <meta property="og:title" content="Home Page - My Website" />
            <meta property="og:description" content="This is the home page of my website." />
            <meta property="og:url" content="https://www.mywebsite.com/" />
            <meta property="og:type" content="website" />
        </Helmet>


        <HeroImage description={t('corporate.description')} title={t('corporate.title')} underlineColor='#ff0000'/>
        <Container sx={{marginTop: 3}}>
            <Typography variant='h1' gutterBottom >{t('home.heading')}</Typography>
            <Typography variant='body1' sx={{whiteSpace: 'pre-line'}} gutterBottom textAlign='justify'>{t('home.description')}</Typography>
        </Container>
        <Box sx={{width: '100%', background: '#000', marginTop: 4, padding: 4}}>
            <Container>
                <Typography color='#fff' variant='h1' gutterBottom >{t('home.heading2')}</Typography>
                <Typography variant='body1' sx={{whiteSpace: 'pre-line'}} gutterBottom color='#fff' textAlign='justify'>{t('home.description2')}</Typography>
            </Container>
        </Box>
        <Container sx={{marginTop: 3, marginBottom: 3}}>
            <Typography variant='h1' gutterBottom >{t('home.heading3')}</Typography>
            <Typography variant='body1' sx={{whiteSpace: 'pre-line'}} gutterBottom textAlign='justify'>{t('home.description3')}</Typography>
            <Grid container>
                {technologies?.map(logo => <Grid item key={logo?.name} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><ImageComponent src={logo?.logo} alt={logo?.name} /></Grid>)}
            </Grid>
        </Container>

        <Loading isLoading={loading}/>
        <Footer/>
    </DrawerAppbar>
}

export default Home