import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {FormControl, ListItemText, MenuItem, Select, Stack, useTheme} from "@mui/material";
import whiteLogo from "../assets/trilogic_logo_monochrome_light.png";
import {Link, useHistory} from "react-router-dom";
import Container from "@mui/material/Container";
import packageJson from '../../package.json';
import menuItems from "../utils/menuItems";
import {useContext, useEffect, useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ConfigContext from "../context/ConfigContext";
import {useTranslation} from "react-i18next";

function DrawerAppbar(props) {
    const {w} = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(0);
    const theme = useTheme();
    const history = useHistory();
    const {t} = useTranslation();
    const {setAppLanguage, language} = useContext(ConfigContext);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLanguageChange = (event) => {
        setAppLanguage(event.target.value);
    };

    const drawer = (
        <Box
            sx={{
                background: '#fff',
                height: '100%',
                paddingTop: theme.spacing(2),
                position: 'relative'
            }}
        >
            <IconButton
                onClick={handleDrawerToggle}
                sx={{
                    color: '#000',
                    zIndex: 100,
                    position: 'absolute',
                    top: '15px',
                    right: '25px'
                }}
            >
                <CloseIcon />
            </IconButton>
            <List sx={{padding: '32px 16px'}}>
                {menuItems.map((item) => {
                    const isExternal = item.url.startsWith('http');
                    const Component = isExternal ? 'a' : Link;
                    const componentProps = isExternal ? {href: item.url, target: '_blank', rel: 'noopener noreferrer'} : {to: item.url};

                    return (
                        <ListItem key={item.name} disablePadding sx={{paddingBottom: '3px', textTransform: 'capitalize'}}
                                  component={Component}
                                  {...componentProps}>
                            <ListItemButton sx={{"&:hover": {background: '#000', color: '#fff'}, background: '#fff', color: '#000'}}>
                                <Typography variant="h1" sx={{textTransform: 'capitalize'}}>{t(item.name)}</Typography>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            <Typography variant='caption' sx={{
                position: 'absolute',
                bottom: 40,
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
                v. {packageJson.version}
            </Typography>
        </Box>
    );

    const container = w !== undefined ? () => w().document.body : undefined;

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("scroll", scrollListener);
    }, []);

    const scrollListener = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setScrollHeight(winScroll);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar style={(props?.fade && scrollHeight < 400) ? {backgroundColor: 'transparent', transition: 'background-color 0.5s ease', color: 'white'} : {backgroundColor: theme.palette.primary[900], transition: 'background-color 0.5s ease', color: 'white'}} position="fixed">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box sx={{flexGrow: 0, display: 'flex'}}>
                            <img src={whiteLogo} alt="Trilogic logo" width='200px' style={{padding: '5px', cursor: "pointer"}} onClick={() => history.push('/')}/>
                        </Box>
                        <Box sx={{display: 'flex', gap: '5px', alignItems: 'center', marginLeft: 'auto', zIndex: '11'}}>
                            <FormControl variant="standard" sx={{paddingRight: 0, width: 'fit-content'}}>
                                <Select
                                    id="language-selector"
                                    value={language}
                                    onChange={handleLanguageChange}
                                    IconComponent={() => null}
                                    sx={{display: 'block', color: '#fff', background: 'none'}}
                                >
                                    <MenuItem value="en">
                                        <Stack direction='row' spacing={0} justifyContent='center' alignItems='center'>
                                            <ListItemText primary="EN"/>
                                        </Stack>
                                    </MenuItem>
                                    <MenuItem value="hu">
                                        <Stack direction='row' spacing={0} justifyContent='center' alignItems='center'>
                                            <ListItemText primary="HU"/>
                                        </Stack>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <div style={{color: '#fff', zIndex: 1000}}>
                                <IconButton
                                    size="large"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleDrawerToggle}
                                    color="inherit"
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </div>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {md: 0}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor="top"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: 'block',
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: '100%',
                            height: '100%',
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, width: '100%'}}
            >
                {props.children}
            </Box>
        </Box>
    );
}

export default DrawerAppbar;
