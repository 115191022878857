import {format} from "date-fns";

const formatHuman = (date) => {
    try {
        return format(date, 'yyyy-MM-dd')
    } catch (e) {
        return null
    }
}

const repairImageUrl = (url, baseUrl) => {
    if (url?.startsWith('http')) return url
    return baseUrl + url
}

const createImageUrl = (image) => {
    if (image) return URL.createObjectURL(image)
    return image
}

const thousandSeparator = (num) => {
    if (num === undefined) return num
    if (num === null) return num
    if (num === '') return num
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const padNumberWithZeros = (num, totalLength) => {
    return num.toString().padStart(totalLength, '0');
}

export {formatHuman, repairImageUrl, thousandSeparator, createImageUrl, padNumberWithZeros}